import { BarsOutlined, BranchesOutlined, CalendarOutlined, CalendarTwoTone, CheckCircleOutlined, CoffeeOutlined, CopyOutlined, DownCircleOutlined, EditOutlined, LoadingOutlined, MailOutlined, OrderedListOutlined, PhoneOutlined, PhoneTwoTone, SafetyCertificateOutlined, SafetyCertificateTwoTone, UpCircleFilled, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, message, Modal, notification, Row, Skeleton, Spin, Tag } from "antd";
import network from "network/network";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const DetailDashboardCustomer = ({ customerId }) => {

    const { t, i18n } = useTranslation();

    const branchColors = {
        1: "#FF6A00",
        3: "#F3C623",
        4: "#10375C",

    }

    const stampNumber = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    var navigate = useNavigate();
    const id = customerId;
    const [stampCount, setStampCount] = useState(0);
    const [giftCount, setGiftCount] = useState(0);
    const [isClientConfirmation, setIsClientConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [nameUser, setNameUser] = useState("");
    const [isUserInputOpen, setIsUserInputOpen] = useState(false);
    const [isMailInputOpen, setIsmailInputOpen] = useState(false);
    const [graphStampState, setGraphStampState] = useState({

        series: [],
        options: {
            chart: {
                type: 'area',
                height: 350,
                zoom: {
                    enabled: false
                }
            },
            colors: ['#FF5733', '#33FF57']

        },


    });

    const [customerData, setCustomerData] = useState({
        customerName: "",
        customerEmail: "",
        customerApprovedDate: new Date(),
        customerNumber: "",
        customerDate: "",
        customerGroups: [],
        customerBranch: "",
        customerBranchId: 0,
        customerConfirmation: false,
        countryCode: "",
        customerStatus: "",
        customerCardCount: 0
    });


    useEffect(() => {
        setCustomerData({
            customerName: "",
            customerEmail: "",
            customerApprovedDate: new Date(),
            customerNumber: "",
            customerDate: "",
            customerGroups: [],
            customerBranch: "",
            customerBranchId: 0,
            customerConfirmation: false,
            countryCode: "",
            customerStatus: "",
            customerCardCount: 0
        })
        selectCustomer();
    }, [customerId])

    const selectCustomer = async () => {

        setLoading(true);
        setIsUserInputOpen(false);
        setIsmailInputOpen(false);

        var params = {}
        var resultStampMaster = await network.get("/admin/Stamp/GetAllStampByClient", params, false);
        var resultCustomers = await network.get("/admin/Client/GetCustomersWithDetail", params, false);
        var resultCard = await network.get("/admin/Stamp/selectStamp", false);
        var resultStampGraphic = await network.get("/admin/Stamp/GetAllYearlyStampsByClientId", { clientId: customerId }, false);

        setGraphStampState({

            series: [{
                name: t('stampCount'),
                data: resultStampGraphic.data.data
            }],
            options: {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },

                title: {
                    text: t('annualStampCustomer'),
                    align: 'left'
                },
                labels: getLast12Months(),
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    opposite: true
                },
                legend: {
                    show: false
                }
            },


        });

        const { data } = resultStampMaster.data;
        var tempStampData = resultStampMaster.data.data;
        var tempCustomerData = resultCustomers.data.data;



        console.log("RESULTSTAMP", tempCustomerData);

        setStampCount(0);

        if (resultStampMaster.isSuccess && resultCustomers.isSuccess && resultCard.isSuccess) {
            tempStampData.map((item) => {
                if (item.clientid == id) {

                    setStampCount(current => current + 1);
                }

                if (item.islaststamp && item.clientid == id) {
                    setGiftCount(current => current + 1);
                }

                if (item.clientid == id && item.clientconfirmationid != 0) {
                    setIsClientConfirmation(true);
                }
            })






            tempCustomerData.map((item) => {
                if (item.customerid == id) {
                    console.log("ITEMMMM", item);

                    setCustomerData({
                        customerId: item.customerid,
                        customerNumber: item.customernumber,
                        customerBranch: item.branchname,
                        customerBranchId: item.branchid,
                        customerDate: item.insertdate,
                        customerConfirmation: item.clientconfirmationid != 0 ? true : false,
                        customerName: item.customername,
                        customerEmail: item.email,
                        customerApprovedDate: item.approveddate,
                        customerGroups: item.groups,
                        countryCode: item.countrycode,
                        customerStatus: item.customerstatus,
                        customerCardCount: item.cardcount
                    })

                    setNameUser(item.customername);
                }
            })



        }

        setTimeout(() => {
            setLoading(false);
        }, 150);

    }

    const handleSubmit = async () => {


        var params = {
            Customerid: parseInt(customerData.customerId),
            Customername: customerData.customerName,
            Customermail: customerData.customerEmail,
            Customernumber: customerData.customerNumber,
            Countrycode: customerData.countryCode,
            Customerstatus: customerData.customerstatus
        };

        var result = await network.post(`/admin/Client/updateClient`, params);

        if (result.isSuccess) {
            var postResult = result.data;
            if (postResult.isSuccess) {


                setIsModalOpen(false);

                var resultCustomers = await network.get("/admin/Client/GetCustomersWithDetail", params, false);
                var resultStampMaster = await network.get("/admin/Stamp/GetAllStampByClient", params, false);


                var tempCustomerData = resultCustomers.data.data;
                const { data } = resultStampMaster.data;

                tempCustomerData.map((item) => {
                    if (item.customerid == id) {
                        setCustomerData({
                            customerId: item.customerid,
                            customerNumber: item.customernumber,
                            customerBranch: item.branchname,
                            customerDate: item.insertdate,
                            customerConfirmation: item.clientconfirmationid != 0 ? true : false,
                            customerName: item.customername,
                            customerEmail: item.email,
                            customerGroups: item.groups,
                            customerApprovedDate: item.approveddate,
                            countryCode: item.countrycode,
                            customerStatus: item.customerstatus,
                            customerCardCount: data.find((i) => i.clientid == id).cardcount
                        })
                    }
                })


                setTimeout(() => {


                }, 250);

            } else {
                alert(postResult.errorMessage);
            }
        } else {
            alert(result.errorMessage);
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    }

    const validateMessages = {
        required: 'Lütfen ${label} giriniz',
        types: {
            email: '${label} formatı doğru değil',

        },

    }

    const getLast12Months = () => {
        var months = [];

        if (i18n.language === "tr") {

            months = [
                "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
                "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
            ];
        } else {

            months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

        }



        const currentMonth = new Date().getMonth(); // Mevcut ayı al (0-11)
        return Array.from({ length: 12 }, (_, i) => months[(currentMonth - i + 12) % 12]).reverse();
    }

    const handleEmailSubmit = () => {
        if (customerData.customerEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerData.customerEmail)) {

            handleSubmit();
            setIsmailInputOpen(!isMailInputOpen);
        } else {
            notification.error({
                message: t('Error'),
                description: t('wrongMailFormat'),
                placement: "topRight",
            });
        }
    }

    const handleNameSubmit = () => {
        if (isUserInputOpen == true) {
            if (customerData.customerName.length != 0) {

                handleSubmit();
                setIsUserInputOpen(!isUserInputOpen);
            } else {
                notification.error({
                    message: t('Error'),
                    description: t('emptyName'),
                    placement: "topRight",
                });
            }
        }
    }



    return (


        <div className="container mx-auto">



            <Form
                layout="vertical"
                name="advanced_search"
                className="ant-advanced-search-form"
            >

                <Row >
                    {loading ? (<Col span={12} className="h-100">
                        <div className="mr-2">
                            <Skeleton active loading={loading}></Skeleton>
                            <Skeleton active loading={loading}></Skeleton>
                            <Skeleton active loading={loading}></Skeleton>
                        </div>


                    </Col>) : (
                        <Col span={12} className="h-100">

                            <div className="d-flex">
                                <div className="mt-1 mr-2">
                                    <img src={`${process.env.PUBLIC_URL}/img/avatars/user-blank-image.jpg`} style={{ width: "40px", height: "40px" }} />
                                </div>
                                <div>
                                    {isUserInputOpen ? (
                                        <div className="d-flex">
                                            <Input onChange={(e) => {
                                                setCustomerData(prevState => ({
                                                    ...prevState,
                                                    customerName: e.target.value

                                                }))

                                            }}
                                                defaultValue={customerData.customerName}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter")
                                                        handleNameSubmit();

                                                }}
                                            ></Input>

                                            <Button className="ml-2" onClick={() => {

                                                if (isUserInputOpen == true) {
                                                    if (customerData.customerName.length != 0) {

                                                        handleSubmit();
                                                        setIsUserInputOpen(!isUserInputOpen);
                                                    } else {
                                                        notification.error({
                                                            message: t('Error'),
                                                            description: t('emptyName'),
                                                            placement: "topRight",
                                                        });
                                                    }
                                                }
                                            }}
                                                type="text"
                                                color="default"
                                                icon={<EditOutlined />}
                                                size="small"
                                            >


                                            </Button>
                                        </div>

                                    ) : (
                                        <div style={{ fontWeight: "bold", fontSize: "17px" }}>
                                            {customerData.customerName}
                                            {/* <span>{customerData.customerNumber}</span> */}
                                            <Button className="ml-2" onClick={() => {
                                                handleSubmit();
                                                setIsUserInputOpen(!isUserInputOpen);
                                            }}
                                                type="text"
                                                color="default"
                                                icon={<EditOutlined />}
                                                size="small"
                                            >

                                            </Button>
                                        </div>
                                    )}
                                    <div style={{ fontSize: "15px" }}><> <p>{customerData.customerNumber}</p></></div>
                                </div>


                            </div>



                            <div className="d-flex">
                                <div className="mt-1 mr-2">
                                    <OrderedListOutlined size={"22px"} />
                                </div>
                                <div >
                                    <div style={{ fontWeight: "bold", fontSize: "17px" }}>{t('registeredBranch')}</div>
                                    <div >

                                        <div style={{ fontSize: "15px" }}><> <p>{customerData.customerBranch}</p></></div>
                                    </div>

                                </div>

                            </div>

                            <div className="d-flex">
                                <div className="mt-1 mr-2">
                                    <UsergroupAddOutlined size={"22px"} />
                                </div>
                                <div >
                                    <div style={{ fontWeight: "bold", fontSize: "17px" }}>{t('customerGroups')}</div>
                                    <div className="d-flex mt-2">
                                        <Tag bordered={false} color="green" >
                                            {t('Group')} 1
                                        </Tag>

                                        <Tag bordered={false} color="cyan" >
                                            {t('Group')} 2
                                        </Tag>

                                        <Tag bordered={false} color="purple" >
                                            {t('Group')} 3
                                        </Tag>
                                    </div>

                                </div>

                            </div>

                            <div className="d-flex mt-3">
                                <div className="mt-1 mr-2">
                                    <MailOutlined size={"22px"} />
                                </div>
                                <div >
                                    <div className="d-flex">

                                        <div style={{ fontWeight: "bold", fontSize: "17px" }}>Email</div>

                                        {/* <div className="ml-3">
                    <Button onClick={() => {
                        handleSubmit();
                        setIsmailInputOpen(!isMailInputOpen);
                    }}
                        type="text"
                        color="default"
                        icon={<EditOutlined />}>

                    </Button>
                </div> */}
                                    </div>

                                    <div className="d-flex ">
                                        {isMailInputOpen ? (
                                            <>

                                                <Input onKeyDown={(e) => {
                                                    if (e.key === "Enter")
                                                        handleEmailSubmit();

                                                }} size="small"

                                                    onChange={(e) => {
                                                        setCustomerData(prevState => ({
                                                            ...prevState,
                                                            customerEmail: e.target.value
                                                        }))

                                                    }}
                                                    defaultValue={customerData.customerEmail}



                                                ></Input>
                                                <Button className="ml-2" style={{ fontSize: "10px" }} onClick={() => {
                                                    handleEmailSubmit();
                                                }}
                                                    type="text"
                                                    color="default"
                                                    icon={<EditOutlined />}>

                                                </Button>
                                            </>


                                        ) : (
                                            <>
                                                {customerData.customerEmail !== null ? (<div>
                                                    {customerData.customerEmail}
                                                    <Button className="ml-2" style={{ fontSize: "10px" }} onClick={() => {
                                                        handleSubmit();
                                                        setIsmailInputOpen(!isMailInputOpen);
                                                    }}
                                                        type="text"
                                                        color="default"
                                                        icon={<EditOutlined />}>

                                                    </Button>
                                                </div>) : (<div>
                                                    {t('notAvaiable')}
                                                    <Button className="ml-2" style={{ fontSize: "10px" }} onClick={() => {
                                                        handleSubmit();
                                                        setIsmailInputOpen(!isMailInputOpen);
                                                    }}
                                                        type="text"
                                                        color="default"
                                                        icon={<EditOutlined />}>

                                                    </Button>
                                                </div>)}


                                            </>
                                        )}

                                    </div>

                                </div>

                            </div>


                            <div className="d-flex mt-3">
                                <div className="mt-1 mr-2">
                                    <CheckCircleOutlined size={"22px"} />
                                </div>
                                <div >
                                    <div style={{ fontWeight: "bold", fontSize: "17px" }}>{t('customerPermission')}</div>
                                    <div className="d-flex ">
                                        {customerData.customerApprovedDate != null ? (<>{new Date(customerData.customerApprovedDate).toLocaleString('tr-TR').replace(/:\d{2}$/, '')} <SafetyCertificateTwoTone style={{ fontSize: "15px" }} className="mt-1 ml-2" twoToneColor={"#02e33e"} /></>) : "Mevcut Değil"}
                                    </div>

                                </div>

                            </div>




                        </Col>
                    )}


                    <Col span={12} className="h-100">

                        <div>

                            <div className="d-flex">
                                <div className="mt-1 mr-2">
                                    <OrderedListOutlined size={"22px"} />
                                </div>
                                <div style={{ fontWeight: "bold", fontSize: "17px" }}>{t('cardNumber')}:</div>
                                <div className="ml-3" style={{ fontSize: "17px" }}>
                                    {customerData.customerCardCount}.{t('Card')}
                                </div>

                            </div>
                            <Card>
                                <div className="d-flex">
                                    {stampNumber.map((item) => (
                                        <div
                                            className="ml-2 d-flex align-items-center justify-content-center"
                                            style={{
                                                backgroundColor: `${stampCount % 10 >= item ? "#FF6A00" : "#F0F0F0"
                                                    }`,
                                                width: "50px",
                                                height: "30px",
                                                // border: "2px solid #ddd",
                                                borderRadius: "50%",
                                                // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                transition: "background-color 0.3s ease-in-out",
                                            }}
                                        >
                                            <p
                                                className="text-center"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    margin: 0,
                                                    color: stampCount % 10 >= item ? "#fff" : "#000",
                                                }}
                                            >
                                                {item}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                            <div >


                                <ReactApexChart options={graphStampState.options} series={graphStampState.series} type="area" height={300} />

                            </div>

                        </div>

                    </Col>

                </Row>
            </Form>


        </div >
    )
}

export default DetailDashboardCustomer;