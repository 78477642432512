// export const API_BASE_URL = 'https://xenon.gbmsoft.com/whatsappBusinnesWater'
// export const API_BASE_URL = 'http://localhost:9528'
// export const API_BASE_URL = 'https://localhost:7174' // Yeni Servis
//export const API_BASE_URL ="https://xenon.gbmsoft.com/WhatsappApiWatterOrderDubai"; // Su Siparişi
// export const API_BASE_URL ="https://xenon.gbmsoft.com/whatsappBusinnesApiBooking"; // Otel rezervasyon
// export const API_BASE_URL = 'http://localhost:5248' // Otel rezervasyon
//export const API_BASE_URL ="https://xenon.gbmsoft.com/whatsappBusinessDemo"
//export const API_BASE_URL ="https://admin.chatshire.io/chatshireadminservice"
// export const API_BASE_URL ="http://admintest.chatshire.io/chatshireadmintestservice2"
export const API_BASE_URL ="https://admintest.chatshire.io/chatshireadmintestservice"
// export const API_BASE_URL ="http://localhost:5219"
export const API_EXTRA_PATH = '/api/v1'
export const API_FULL_URL =API_BASE_URL + API_EXTRA_PATH