import React, { Component, useEffect, useState, useRef } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Menu } from 'antd';
import { Progress, Tooltip, Tag, notification } from 'antd';
import { Modal, Select, Badge, Descriptions, Table, } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { BRANCH_ID, FIRM_ID, FIRM_NAME } from 'constants/StorageConstant';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import network from 'network/network';
import { appListener } from 'utils/redux/actions/appListenerActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import helperFunc from 'helpers/helperFunc';
import { useNavigate, useParams } from 'react-router-dom';
import './Table.css';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import * as signalR from '@aspnet/signalr';
import { API_BASE_URL } from 'constants/ApiConstant';
import globalConstant from 'constants/globalConstant';
import { GiftOutlined, CheckOutlined, RetweetOutlined, CloseOutlined, CalendarOutlined, StopOutlined, PhoneOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

const firmName = localStorage.getItem(FIRM_NAME);

const { TabPane } = Tabs;

const BaristaScreen = (props) => {

    const { t, i18n } = useTranslation();
    const [selectData, setSelectData] = useState([]);
    const [selectListenerData, setSelectListenerData] = useState([]);
    const [isGiftRequest, setIsGiftRequest] = useState(true);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [CancelOpen, setCancelOpen] = useState(false);
    const signalRConnectionRef = useRef(null);
    const firmId = localStorage.getItem(FIRM_ID);
    const branchId = localStorage.getItem(BRANCH_ID);
    const isConnectListener = useRef(false);
    const [openModalDetail, setOpenModalDetail] = useState([]);
    const [openModalCancel, setOpenModalCancel] = useState([]);
    const [cancelReasons, setCancelReasons] = useState([]);
    const [selectedCancelReasonId, setSelectedCancelReasonId] = useState(cancelReasons.length > 0 ? cancelReasons[0].stampcancelreasonid : undefined);
    const [notificationShown, setNotificationShown] = useState(false);

    const handleSelectChange = (value) => {
        setSelectedCancelReasonId(value.value);
    };

    const showModal = (elm) => {
        setOpen(true);
        setOpenModalDetail(elm);
    };
    const showCancelModal = (elm) => {
        setCancelOpen(true);
        setOpenModalCancel(elm);
    };
    const handleOk = () => {
        setOpen(false);
        onFinish(openModalDetail.clientnumber, openModalDetail.isgiftrequest)
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const handleCancelOk = () => {
        setCancelOpen(false);
        onCancel(openModalCancel.clientnumber, selectedCancelReasonId)
    };

    const handleCancelCancel = () => {
        setCancelOpen(false);
    };
    const [campaignState, setCampaignState] = useState({
        customerName: "",
        customerPhoneNumber: "",
        customerStampNumber: "",
        sentStampDate: "",
    });
    const [stampState, setStampState] = useState(false);
    const [section, setSection] = useState(false);


    useEffect(() => {
        responseData();
        cancelData();
    }, []);

    useEffect(() => {
        if (cancelReasons.length > 0) {
            setSelectedCancelReasonId(cancelReasons[0].stampcancelreasonid);
        }
    }, [cancelReasons]);


    useEffect(() => {
        if (isConnectListener.current === false) {
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${API_BASE_URL}/stamphub`, {})
                .build();

            const hubConnectionAdmin = new signalR.HubConnectionBuilder()
                .withUrl(`${API_BASE_URL}/messagehub`, {})
                .build();

            signalRConnectionRef.current = hubConnection;

            if (localStorage.getItem('isFirmAdmin') === 'true') {

                hubConnectionAdmin.on('whatsappListener_' + firmId, onStampRequest);
            } else {

                hubConnection.on('stampListener_' + firmId + '_' + branchId, onStampRequest);
            }

            // hubConnection.on('whatsappListener_' + firmId + onStampRequest);



            function startConnection() {

                if (localStorage.getItem('isFirmAdmin') === 'true') {
                    hubConnectionAdmin.start()
                        .then(() => {
                            console.log("stamp connected");
                            isConnectListener.current = true;
                        })
                        .catch(err => {
                            console.log(err);
                            reconnect();
                        });
                } else {
                    hubConnection.start()
                        .then(() => {
                            console.log("stamp connected");
                            isConnectListener.current = true;
                        })
                        .catch(err => {
                            console.log(err);
                            reconnect();
                        });
                }



            }

            function reconnect() {
                console.log('reconnecting...');
                setTimeout(startConnection, 2000);
            }

            hubConnection.onclose(reconnect);

            startConnection();
        }
    }, []);
    function playSound() {

        const sound = "/notification.mp3";
        var audio = new Audio(sound);

        setTimeout(() => {
            audio.play();
        }, 500);

        setTimeout(() => {
            audio.remove();
        }, 2500);

    }

    const onStampRequest = (data) => {
        let newModel = {};
        if (data.type == "newstamp" && !notificationShown) {
            let parsedData = JSON.parse(data.payload);
            console.log("PARSEDD", parsedData);
            newModel.tempstampid = parsedData.TEMPSTAMPID;
            newModel.clientid = parsedData.CLIENTID;
            newModel.isgiftrequest = parsedData.ISGIFTREQUEST;
            newModel.status = parsedData.STATUS;
            newModel.insertdate = parsedData.INSERTDATE;
            newModel.clientname = parsedData.CLIENTNAME;
            newModel.clientnumber = parsedData.CLIENTNUMBER;
            newModel.cardcount = parsedData.CARDCOUNT;
            newModel.stampcount = parsedData.STAMPCOUNT;
            setSelectData((state) => [...state, newModel]);
            playSound();
            notification.open({
                message: t('stampRequest'),
                description: t('stampReqCome'),
                duration: 3,
                placement: 'topRight',
                icon: <CheckOutlined style={{ color: '#52c41a' }} />
            });
            setNotificationShown(true);
        }
        else if (data.type == "deletestamp") {
            responseData();
        }
    }
    const responseData = async () => {

        var result = localStorage.getItem('isFirmAdmin') == true ? await network.get("/admin/Stamp/selectStamp", false) : await network.get("/admin/Stamp/GetAllWaitingStamp", false);
        const { data } = result.data;
        console.log("DATAA", data);
        setSelectData(data)
    }

    const cancelData = async () => {
        try {
            const result = await network.get("/admin/Stamp/selectStampCancel", false);
            const { data } = result.data;
            setCancelReasons(data);
            setLoading(false);
        } catch (error) {
            console.error('Veri alınamadı:', error);
            setLoading(false);
        }
    };



    const StampPrevious = (elm) => {

        helperFunc.showConfirm({
            content: `Test. adlı müşterinin damga isteği kabul edilecek. Devam edilsin mi`,
            title: "Onayla ",
            onConfirm: () => onFinish(elm.clientnumber, false)
        });
    }

    const onFinish = async (clientNumber, Status) => {
        var params = {
            clientNumber: clientNumber,
            isGiftRequest: Status,
            stampStatus: true
        }
        console.log(params);
        var result = await network.post(`/admin/Stamp/insertStamp`, params);
        // var result = true;
        if (result.isSuccess) {
            var postResult = result;
            if (postResult.isSuccess) {
                message.success(t('stampOkey'));
                setTimeout(() => {
                }, 250);
                responseData();

            } else {
                alert(postResult.errorMessage);
            }
            setSection(true);
        } else {
            alert(result.errorMessage);
        }
        if (result) {
            notification.success({
                message: t('Succesful'),
                description: t('stampOkey'),
                placement: "topRight",
            });
            responseData();

        } else {
            notification.error({
                message: `Hata!`,
                // description: result.errorMessage,
                placement: "topRight",
            });
        }

    }



    const onCancel = async (clientNumber, cancelReasonId) => {
        var params = {
            clientNumber: clientNumber,
            cancelReasonId: cancelReasonId,
        }
        var result = await network.post(`/admin/Stamp/cancelStamp`, params);

        if (result.isSuccess) {
            var postResult = result;
            if (postResult.isSuccess) {
                message.success(t('stampCancelled'));
                responseData();

            } else {
                alert(postResult.errorMessage);
            }
            setSection(true);
        } else {
            alert(result.errorMessage);
        }
        if (result) {
            notification.success({
                message: t('Succesful'),
                description: t('stampCancelled'),
                placement: "topRight",
            });
            responseData();
        } else {
            notification.error({
                message: `Hata!`,
                // description: result.errorMessage,
                placement: "topRight",
            });
        }

    }

    const SplitHours = (date) => {
        var t = new Date(date);
        var saat = t.getHours().toString().padStart(2, '0');
        var dakika = t.getMinutes().toString().padStart(2, '0');
        return <>{(saat + ":" + dakika)}</>
    }
    const SplitDate = (date) => {
        var aylar = [];

        if (i18n.language == "tr") {
            aylar = new Array("Ocak", "Şubat", "Mart",
                "Nisan", "Mayıs", "Haziran", "Temmuz", "Agustos", "Eylül",
                "Ekim", "Kasım", "Aralık");
        }else{
            aylar = new Array("January", "February", "March",
                "April", "May", "June", "July", "August", "September",
                "October", "November", "December");
        }

        var t = new Date(date);
        var gun = t.getDate();
        var ay = t.getMonth();
        var yil = t.getFullYear();

        return <>{(gun + " " + aylar[ay])}</>
    }
    const ClientName = (CName) => {
        var Name = CName.split(' ');
        if (Name.length == 2) {
            return <>{Name[0] + " " + Name[1].slice(0, 1) + "."}</>
        }
        if (Name.length == 3) {
            return <>{Name[0] + " " + Name[1] + " " + Name[2].slice(0, 1) + "."}</>
        }

        return <>{Name}</>

    }
    const ClientNumber = (Number) => {

        var secNumber = Number.slice(8, 12)

        return <>{"**** ***" + " " + secNumber}</>


    }
    const coffeeCircleFunc = (stampNumber) => {
        let list = []
        const coffeCircList = () => {
            for (let i = 1; i <= 9; i++) {
                if (i <= stampNumber) {
                    list.push(<Col className='ml-1 d-flex align-items-center justify-content-center'>
                        <span className='d-flex align-items-center justify-content-center' style={{
                            borderWidth: 2,
                            borderStyle: "solid",
                            borderColor: "#0A345A", borderRadius: '50px', width: '45px', height: '45px',
                        }}>
                            <div style={{ fontSize: '25px' }}><img className='p-2' src='/img/avatars/coffeeproject.png' width={50}></img></div>
                        </span>
                    </Col>)
                }
                else {
                    list.push(<Col className='ml-1 d-flex align-items-center justify-content-center'>
                        <span className='d-flex align-items-center justify-content-center' style={{
                            borderWidth: 2,
                            borderStyle: "solid",
                            borderColor: "#0A345A", borderRadius: '50px', width: '45px', height: '45px',
                        }}>
                            <div style={{ fontSize: '25px' }}>{i}</div>
                        </span>
                    </Col>)
                }
            }
            return list;
        }
        return coffeCircList();
    }

    const [columns, setColumns] = useState([
        {
            title: t('customerInfo'),
            dataIndex: 'GroupName',
            key: 'groupname',
            width: '420px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <>
                    <Row className='d-flex align-items-center  '>
                        <Col>
                            <div className=' ' style={{ fontSize: '25px' }} >
                                {ClientName(record.clientname)}
                            </div>
                        </Col>
                        <Col>
                            <Tag color='blue' className='ml-2 d-flex align-items-center justify-content-center'>
                                <div style={{ fontSize: '14px' }} >
                                    {record.cardcount === 0
                                        ? `1.${t('Card')}`
                                        : (record.stampcount === 0
                                            ? (record.cardcount + 1) + `.${t('Card')}`
                                            : record.cardcount + `.${t('Card')}`)
                                    }
                                </div>
                            </Tag>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ fontSize: '23px' }} >{ClientNumber(record.clientnumber)}</div>
                        </Col>
                    </Row>
                    <Row>
                        <span className=''>
                            <Tag style={{ margin: 0 }} color='red'><CalendarOutlined className='mt-1' style={{ fontSize: '18px' }} /></Tag></span>
                        <span className='mx-1' style={{ fontSize: '20px' }} >{SplitDate(record.insertdate)}</span>
                        <span className='mx-1'>
                            <Tag style={{ margin: 0 }} color='red'><ClockCircleOutlined className='mt-1' style={{ fontSize: '18px' }} /></Tag></span>

                        <span className='mr-1' style={{ fontSize: '20px' }}> {SplitHours(record.insertdate)}</span>
                    </Row>
                </>
            )
        },
        {
            title: t('stampInfo'),
            dataIndex: 'id',
            width: '550px',
            align: 'center',
            render: (_, elm) => {
                return (
                    <div>
                        <div>

                            <Card bodyStyle={{ padding: 0, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='my-3 d-flex align-items-center justify-content-center'>

                                    <div style={{ fontSize: '25px' }} >
                                        <b>{elm.stampcount} / 9</b>
                                    </div>
                                </Row>
                                <Row className='mt-2 d-flex align-items-center justify-content-center'>
                                    {coffeeCircleFunc(elm.stampcount)}

                                </Row>
                                <Row className='my-3 align-items-center justify-content-center'>
                                </Row>
                            </Card>

                        </div>
                    </div>

                )
            }

        },
        {
            title: t('Transactions'),
            dataIndex: 'id',
            width: '320px',
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <>
                        <div style={{ position: 'absolute' }}>

                        </div>
                        <Row>
                            <Col span={15} className='mr-2 '>
                                {elm.isgiftrequest ?
                                    <Button onClick={() => showModal(elm)} className='' style={{ padding: 0, background: "#f17304", color: "white", width: '100%', height: '90px', padding: '0px' }} size={"large"}>
                                        <b><GiftOutlined style={{ fontSize: 18 }} className='mr-1 ' /></b>
                                        <span style={{ fontSize: '17px' }}>{t('coffeeGift').toUpperCase()}</span>
                                    </Button>
                                    :
                                    <Button onClick={() => showModal(elm)} className='' style={{ padding: 0, background: "#0a3359", color: "white", width: '100%', height: '90px', padding: '0px' }} size={"large"}>
                                        <b><CheckOutlined style={{ fontSize: 18 }} className='mr-1 ' /></b>
                                        <span style={{ fontSize: '17px' }}>{t('Stamp').toUpperCase()}</span>
                                    </Button>
                                }

                            </Col>
                            <Col span={7}>
                                <Button onClick={() => showCancelModal(elm)} className='' style={{ padding: 0, width: '100%', height: '90px' }} size={"large"} danger>
                                    <b className='mt-'><CloseOutlined style={{ fontSize: 18 }} /></b>
                                    <span style={{ fontSize: '17px' }}>{t('Cancel').toUpperCase()}</span>
                                </Button>
                            </Col>
                        </Row>
                    </>
                )
            }
        },
    ])

    return (
        <>
            <Helmet>
                <title>{firmName}{"| Chatshire Admin Panel"}</title>
            </Helmet>
            <div>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab={t('stampConfirmationScreen')} key="1">
                        <Card >

                            <Col span={24}><Table
                                pagination={{ defaultPageSize: 50 }}
                                className="table-striped-rows"
                                columns={columns}
                                // loading={props.isLoading}
                                dataSource={selectData}
                                rowKey='id'

                            /></Col>
                        </Card>
                    </TabPane>

                </Tabs>

                <Modal
                    style={{ width: 300, height: 300 }}
                    open={open}
                    title={<div style={{ fontSize: '22px' }}>{t('Confirmation').toUpperCase()}</div>}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    closeIcon={false}
                    closable={false}
                    footer={[

                        <div className='m-2'>
                            <Button className='mr-1' style={{ width: '180px', height: '85px' }} key="back" onClick={handleCancel}>
                                <div style={{ fontSize: '23px' }}>{t('Close')}</div>
                            </Button>
                            <Button className='mr-1' style={{ width: '180px', height: '85px' }} key="submit" type="primary" loading={loading} onClick={handleOk}>
                                <div style={{ fontSize: '23px' }} >{t('Confirm')}</div>
                            </Button></div>
                    ]}
                >
                    {openModalDetail.isgiftrequest ? <div className='my-4' style={{ fontSize: '24px' }}  >{t('freeCoffeMessage')}</div> : <div className='my-4' style={{ fontSize: '24px' }}  >{t('confirmCoffeeMessage')}</div>}
                </Modal>
                <Modal

                    open={CancelOpen}
                    title={<div style={{ fontSize: '22px' }}>{t('Cancellation').toUpperCase()}</div>}
                    onOk={handleCancelOk}
                    onCancel={handleCancelCancel}
                    width={700}
                    closable={false}
                    footer={[

                        <div className='m-2'>
                            <Button className='mr-1' style={{ width: '180px', height: '85px' }} key="back" onClick={handleCancelCancel}>
                                <div style={{ fontSize: '23px' }}>{t('Close')}</div>
                            </Button>
                            <Button className='mr-1' style={{ width: '180px', height: '85px' }} key="submit" type="primary" loading={loading} onClick={handleCancelOk}>
                                <div style={{ fontSize: '23px' }} >{t('Cancel')}</div>
                            </Button></div>
                    ]}
                >
                    <div style={{ fontSize: '24px' }}  >{t('cancelCoffeeMessage')}</div>

                    <br></br>
                    <br></br>

                    <Row >
                        <div className=' mr-3 d-flex align-items-center justify-content-center' style={{ fontSize: '24px' }}>
                            {t('cancelReason')}
                        </div>
                        <Select
                            id='stateSelect'
                            labelInValue
                            defaultValue={{ value: cancelReasons.length > 0 ? cancelReasons[0].stampcancelreasonid : undefined }}
                            style={{ width: 280 }}
                            className='my-2 mb-1'
                            size={"large"}
                            onChange={(value) => handleSelectChange(value)}
                        >
                            {cancelReasons.map(reason => (
                                <Select.Option key={reason.stampcancelreasonid} value={reason.stampcancelreasonid}>
                                    {reason.reason}
                                </Select.Option>
                            ))}
                        </Select>
                    </Row>


                </Modal>
            </div>
        </>
    );
}
// export default BaristaScreen
function mapDispatchToProps(dispatch) {
    return {
        appListenerAction: bindActionCreators(appListener, dispatch),
    }
}
function mapStateToProps(state) {
    return {
        stampList: state.stampReducer.stampList,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BaristaScreen));